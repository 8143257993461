import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"

function NotFound() {
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                    <Link to="/" className="breadcrumb-item">
                        Home
                    </Link>
                    <li className="breadcrumb-item active" aria-current="page">
                        Pagina no encontrada
                </li>
                </ol>
            </nav>
            <div className="container d-block justify-content-center align-items-center mt-5" style={{height: "80vh"}}>
                <FontAwesomeIcon icon={faSearch} className="iconos" style={{ fontSize: "10rem" }} />
                <h1 className="text-center card-title">Oops...</h1>
                <h2 className="text-center card-title">La dirección a la que has intentado acceder no existe</h2>
                <Link to="/" >
                    <FontAwesomeIcon icon={faChevronCircleLeft} className="iconos" style={{ fontSize: "3rem" }} />
                    <h3 className="text-center card-title">Regresar</h3>
                </Link>
            </div>
        </div>
    )
}

export default NotFound
