import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Mineria() {
    return (
        <div>
            <BreadcrumbProducts current="Soluciones para la minería" />
            <div className="header-mineria">
                <h1 className="text-center pb-5 pt-5 category-title letra">Minería</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">La extracción de minerales preciosos, como el oro y demás necesita del equipo adecuado para hacerlo de una manera óptima y obtener la mayor rentabilidad. La actividad minera tiene sus herramientas garantizadas en nuestra ferretería, con mangueras de poliuretano, generadores de vacío, válvulas y mucho más.</h4>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/bomba-vacio.jpg" alt="Bombas de vacío" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Bombas de vacío</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/generador-vacio.jpg" alt="Generador de Vacío" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Generadores de vacío</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/mangueras-poliuretano.jpg" alt="Mangueras de poliuretano" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Mangueras de Poliuretano</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/valvula-check.jpg" alt="Válvula Check" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Válvula Check</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/valvula-compuerta.jpg" alt="Válvulas de compuerta" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Valvulas de Compuerta</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/valvula-mariposa.jpg" alt="Válvulas Mariposa" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Válvulas Mariposa</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/valvula-solenoide.jpg" alt="Válvulas Solenoide" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Válvulas Solenoide</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mineria/valvula-paso.jpg" alt="Válvulas de proceso" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Válvulas de Proceso</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mineria
