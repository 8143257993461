import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

import Header from './components/Header';
import NotFound from './components/NotFound';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton'

// main views imports
import Home from './views/Home';
import Contact from './views/Contact';
import Services from './views/Services';
import Categories from './views/Categories';

// products categories imports
import AceroInoxidable from './views/products/AceroInoxidable';
import Conexiones from './views/products/Conexiones';
import Electricidad from './views/products/Electricidad';
import EquiposElectricos from './views/products/EquiposElectricos';
import Ferreteria from './views/products/Ferreteria';
import Hierro from './views/products/Hierro';
import Mallas from './views/products/Mallas';
import Mineria from './views/products/Mineria';
import Pinturas from './views/products/Pinturas';
import Plastico from './views/products/Plastico';
import SeguridadIndustrial from './views/products/SeguridadIndustrial';
import Soldaduras from './views/products/Soldaduras';
import Techos from './views/products/Techos';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <div className="app">
        <ScrollToTopButton showBelow={400} />
        <div className="container">
          <Switch>
            {/* main views */}
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/productos">
              <Categories />
            </Route>
            <Route exact path="/servicios">
              <Services />
            </Route>
            <Route exact path="/contacto">
              <Contact />
            </Route>
            {/* products views */}
            <Route exact path="/productos/acero-inoxidable">
              <AceroInoxidable />
            </Route>
            <Route exact path="/productos/conexiones">
              <Conexiones />
            </Route>
            <Route exact path="/productos/electricidad">
              <Electricidad />
            </Route>
            <Route exact path="/productos/equipos-electricos">
              <EquiposElectricos />
            </Route>
            <Route exact path="/productos/ferreteria">
              <Ferreteria />
            </Route>
            <Route exact path="/productos/hierro">
              <Hierro />
            </Route>
            <Route exact path="/productos/mallas">
              <Mallas />
            </Route>
            <Route exact path="/productos/mineria">
              <Mineria />
            </Route>
            <Route exact path="/productos/pinturas">
              <Pinturas />
            </Route>
            <Route exact path="/productos/plasticos">
              <Plastico />
            </Route>
            <Route exact path="/productos/seguridad-industrial">
              <SeguridadIndustrial />
            </Route>
            <Route exact path="/productos/soldaduras">
              <Soldaduras />
            </Route>
            <Route exact path="/productos/techos">
              <Techos />
            </Route>
            {/* error 404 */}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
