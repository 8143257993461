import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';


function AceroInoxidable () {
    return (
        <div>
            <BreadcrumbProducts current="Acero Inoxidable" />
            <div className="header-acero">
                <h1 className="text-center pb-5 pt-5 category-title letra">Acero Inóxidable</h1>
            </div>
            <div className="description">
                <p className="text-justify pb-3 pt-2">Entre los muchos tipos de aceros resistentes, hay algunos que son resistentes a la corrosión, es decir, son inoxidables. Estos aceros se caracterizan por su resistencia a la corrosión atmosférica porque cuando se alean con otros metales como el cromo y el níquel, son menos reactivos.</p>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/acero/malla/mesh_04.jpg" alt="Rollo de Malla Mesh" srcset="" />
                        <div className="card-body">
                            <h3 className="card-title">Mallas Mesh</h3>
                            <p className="card-text text-secondary text-justify">Normalmente utilizada para la clasificación de tamaños de productos y su separación. Así como también para la decoración y fabricación de cercas. El acero inoxidable es el principal tipo de acero para la fabricación de la malla mesh, normalmente tipo 304.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/acero/tubos/tubo_02.jpg" alt="Tubos de acero" />
                        <div className="card-body">
                            <h3 className="card-title">Tubos</h3>
                            <p className="card-text text-secondary text-justify">Los tubos de acero inoxidable son ampliamente utilizados, se les valora por su resistencia y capacidad de soportar temperaturas y presión extremas, así como elementos destructivos. Ofrecen bajo nivel de corrosión, son robustos y duraderos.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/acero/valvulas/valvulas.jpg" alt="Tubos de acero" />
                        <div className="card-body">
                            <h3 className="card-title">Válvulas</h3>
                            <p className="card-text text-secondary text-justify">Es un instrumento de regulación y control de fluidos, su fabricación en acero y el contenido de cromo promueve una resistencia considerable a la corrosión y la oxidación. Consultanos para obtener la que mejor se adapte a sus necesidades.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AceroInoxidable
