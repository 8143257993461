import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Techos() {
    return (
        <div>
            <BreadcrumbProducts current="Techos" />
            <div className="header-techo">
                <h1 className="text-center pb-5 pt-5 category-title letra">Techos</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Lamilit, superlit, losacero, zinc, lo más buscado para el laminado de techos lo encuentras en nuestra ferretería, súmale calidad a tu construcción.</h4>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/techos/galvanizado.jpg" alt="Láminas Techos Galvanizados" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Galvanizados</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/techos/lamilit.jpg" alt="Láminas de techos lamilit" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Lamilit</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/techos/superlit.jpg" alt="Láminas de superlit" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Superlit</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/techos/losacero.jpg" alt="Láminas de losacero" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Losacero</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/techos/zinc.jpg" alt="Láminas de Zinc" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Zinc</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Techos
