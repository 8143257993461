import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Ferreteria() {
    return (
        <div>
            <BreadcrumbProducts current="Ferretería" />
            <div className="header-ferreteria">
                <h1 className="text-center pb-5 pt-5 category-title letra">Ferretería</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Tenemos todo el equipo de ferretería que necesitas, desde implementos de albañilería, construcción, herramientas, cerraduras, sanitarios, mantos, cemento, pego, cal y más. No dejes de visitarnos y conocer todo lo que la Ferretería Maxim Guayana C.A. tiene para ti.</h4>
            </div>
            <h3 className="text-center category-title" >Albañilería</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/albanil/cucharas.jpg" alt="Cucharas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cucharas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/albanil/nivel.jpg" alt="Herramienta nivel de albañil" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Niveles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/albanil/palas.jpg" alt="Palas de albañil" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Palas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/brochas/brochas.jpg" alt="Brochas para pintura" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Brochas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/pego/pego.jpg" alt="Pegamento para cerámicas y baldosas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Pego</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/cal/cal.jpg" alt="Cal" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cal</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Bisagras</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/bisagras/doradas.jpg" alt="Bisagras simples doradas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Simples</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/bisagras/doble-accion.jpg" alt="Bisagras doble acción" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Doble acción</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/bisagras/embutir.jpg" alt="Bisagra para embutir" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Para embutir</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Candados</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/candados/anticizalla.jpg" alt="Candado anticizalla" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Anticizalla</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/candados/candados.jpg" alt="Candado de arco" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De arco</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/candados/cisa-dorado.jpg" alt="Candado económico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Sencillos</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Cerraduras</h3>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/cerraduras/embutir.jpg" alt="Cerradura para embutir" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Para Embutir</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/cerraduras/pomo-metal.jpg" alt="Cerradura de pomo de metal" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De Pomo</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/cerraduras/mango-dorada-run.jpg" alt="Cerradura de mango metálico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De Mango</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/cerraduras/sobreponer.jpg" alt="Cerradura para sobreponer" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Para sobreponer</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Discos</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/discos/corte-boch.jpg" alt="Disco de corte" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De corte</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/discos/rectificar-metal.jpg" alt="Disco de corte para rectificación de metal" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Rectificación de Metal</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/discos/trozadora.jpg" alt="Garruchas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Para Tronzadoras</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Equipos</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/garruchas/garruchas.jpg" alt="Garruchas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Garruchas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/ruedas/ruedas.jpg" alt="Ruedas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Ruedas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/trolley/trolleys.jpg" alt="Trolley" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Trolley</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Herramientas</h3>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/herramientas/alicates.jpg" alt="Alicates" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Alicates</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/herramientas/destornilladores.jpg" alt="Destornilladores" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Destornilladores</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/herramientas/llaves-1.jpg" alt="Llaves hexagonales de presión y ajustable" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Llaves</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/herramientas/martillo.jpg" alt="Martillos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Martillos</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Llaves</h3>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/llaves/llave-arresto.jpg" alt="Llave de arresto" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De arresto</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/llaves/paso.jpg" alt="Llave de paso" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">De paso</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/llaves/paso-plastico.jpg" alt="Llave de paso plástica" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Plásticas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/llaves/paso-corona.jpg" alt="Llave de paso Corona" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Corona</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Mantos</h3>
            <div className="row">
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/mantos/negro.jpg" alt="Manto Negro" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Manto Negro</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/mantos/aluminizado.jpg" alt="Manto Aluminizado" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Manto Aluminizado</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Sanitarios</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/bajante.jpg" alt="Bajantes" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Bajantes</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/duchas.jpg" alt="Duchas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Duchas y llaves para duchas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/herraje.jpg" alt="Herrajes" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Herrajes</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/llaves-lavamanos.jpg" alt="Llaves para lavamanos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Llaves para lavamanos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/llaves-lavaplatos.jpg" alt="Llaves para lavaplatos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Llaves para lavaplatos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/ferreteria/sanitarios/sala-bano-2.jpg" alt="Salas de baño" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Salas de baño</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ferreteria
