import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Mallas() {
    return (
        <div>
            <BreadcrumbProducts current="Mallas" />
            <div className="header-mallas">
                <h1 className="text-center pb-5 pt-5 category-title letra">Mallas</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">En ciertos tipos de construcciones se requiere el uso de mallas específicas para brindar protección, resguardo y seguridad a ciertas áreas. Así como también, ayudar a otorgar solides a suelos, techos y paredes.</h4>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mallas/electroforjadas.jpg" alt="Mallas electrosoldadas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Electrosoldadas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mallas/expandida.jpg" alt="Mallas Expandidas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Expandidas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mallas/piso.jpg" alt="Mallas para piso" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Para Piso</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/mallas/rejilla-grating.jpg" alt="Rejilla Grating" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Rejillas Grating</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mallas
