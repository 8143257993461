import React from 'react';
import Cards from '../components/Cards'
import { Link } from 'react-router-dom';

function Categories() {
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                    <Link to="/" className="breadcrumb-item">
                        Inicio
                    </Link>
                    <li className="breadcrumb-item active" aria-current="page">
                        Productos
                </li>
                </ol>
            </nav>
            <Cards />
        </div>
    )
}

export default Categories
