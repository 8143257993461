import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Electricidad() {
    return (
        <div>
            <BreadcrumbProducts current="Electricidad" />
            <div className="header-electricidad">
                <h1 className="text-center pb-5 pt-5 category-title letra">Electricidad</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Comercializamos y distribuímos materiales para trabajos eléctricos tanto para el hogar como para la industria, obten las herramientas y suministros necesarios para los proyectos que necesiten de materiales de calidad en el apartado de electricidad.</h4>
            </div>
                <h3 className="text-center category-title" >Bombillos</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/bombillos/ahorradores.jpg" alt="Bombillos ahorradores" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Ahorradores</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/bombillos/incandecentes.jpg" alt="Bombillos incandecentes" />
                        <div className="card-body">
                            <h4 className="card-title">Incandecentes</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/bombillos/led.jpg" alt="Bombillos led" />
                        <div className="card-body">
                            <h4 className="card-title">Led</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Breakers</h3>
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <img className="card product-img" src="/img/productos/electricidad/breaker/breakers.jpg" alt="breakers electricos varios" srcset=""/>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <img className="card product-img" src="/img/productos/electricidad/breaker/bticino.jpg" alt="breakers electricos varios" srcset="" />
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <img className="card product-img" src="/img/productos/electricidad/breaker/uni-dipolo.jpg" alt="breakers electricos varios" srcset="" />
                </div>
            </div>
            <h3 className="text-center category-title" >Cables Eléctricos</h3>
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <img className="card product-img" src="/img/productos/electricidad/cables/puntas.jpg" alt="Puntas de cables" srcset="" />
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <img className="card product-img" src="/img/productos/electricidad/cables/rollos.jpg" alt="Rollos de cable eléctrico" srcset="" />
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <h5 className="text-justify">Un cable eléctrico tiene la finalidad de transportar la energía eléctrica de un punto a otro. En función de su aplicación final, los cables pueden tener diferentes configuraciones, basando siempre su diseño según normativas nacionales e internacionales. Un equipo de aire acondicionado requiere de más corriente para funcionar que un bombillo, le brindamos asesoría para que pueda adquirir el mejor adaptado a sus necesidades, no dude en contactarnos.</h5>
                </div>
            </div>
            <h3 className="text-center category-title" >Cajetines</h3>
            <hr />
            <h4 className="text-center">Metálicos</h4>
            <hr />
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/rectangular-metal.jpg" alt="Cajetín rectangular metálico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines metálicos sencillos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/doble-metal.jpg" alt="Cajetín doble metálico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines metálicos dobles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/hexagonal-metal.jpg" alt="Cajetín octogonal metálico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines metálicos octogonales</h4>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h4 className="text-center">Plásticos</h4>
            <hr />
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/rectangular-plastico.jpg" alt="Cajetín rectangular plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines plástico sencillos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/doble-plastico.jpg" alt="Cajetín doble plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines plásticos dobles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/cajetines/octogonal-plastico.jpg" alt="Cajetín octogonal plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cajetines plásticos octogonales</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Interruptores</h3>
            <hr />
            <h4 className="text-center">Económicos</h4>
            <hr />
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/sencillo-beige.jpg" alt="Interruptor económico de una sola fase" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores Sencillos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/doble-beige.jpg" alt="Interruptor económico doble" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores Dobles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/toma-beige.jpg" alt="Interruptor económico con toma" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores con tomas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h4 className="text-center">Estilizados</h4>
            <hr />
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/sencillo-blanco.jpg" alt="Interruptor estilizado blanco sencillo" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores sencillos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/doble-blanco.jpg" alt="Interruptor estilizado blanco doble" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores Dobles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/interruptores/toma-blanco.jpg" alt="Interruptor estilizado blanco con toma" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Interruptores con tomas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Reflectores</h3>
            <div className="row">
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/reflectores/150w.jpg" alt="Relector 150w" srcset="" />
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/reflectores/450w.jpg" alt="Reflector 450w" srcset="" />
                    </div>
                </div>
                <div className="col-md-4 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/reflectores/led.jpg" alt="Reflector Led" srcset="" />
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Tableros</h3>
            <div className="row">
                <div className="col-md-3 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/tablero/bifasico-blanco.jpg" alt="Relector 150w" srcset="" />
                    </div>
                </div>
                <div className="col-md-3 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/tablero/bifasico-gris.jpg" alt="Reflector 450w" srcset="" />
                    </div>
                </div>
                <div className="col-md-3 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/tablero/bifasico-blanco-metal.jpg" alt="Cajetín rectangular metálico" srcset="" />
                    </div>
                </div>
                <div className="col-md-3 d-flex mb-2">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/electricidad/tablero/bifasico-gris-metal.jpg" alt="Cajetín rectangular metálico" srcset="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Electricidad
