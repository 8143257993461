import React from 'react';
import { Link } from 'react-router-dom';

import imgMineria from '../assets/img/productos/mineria.jpg'
import imgHierro from '../assets/img/productos/hierro.jpg'
import imgPlastico from '../assets/img/productos/plastico.jpg'
import imgTechos from '../assets/img/productos/techos.jpg'

function Highlights() {
    return (
        <div className="row">
            <div className="col-md-3 d-flex">
                <div className="card rounded-0 text-center mb-2">
                    <img src={imgMineria} alt="Minería" srcset="" />
                    <div className="card-body">
                        <h4 className="card-title">Minería</h4>
                        <p className="card-text text-secondary text-justify">La extracción de minerales preciosos, como el oro y demás necesita del equipo adecuado para hacerlo de una manera óptima y obtener la mayor rentabilidad. La actividad minera tiene sus herramientas garantizadas en nuestra ferretería, con mangueras de poliuretano, generadores de vacío, válvulas y mucho más.</p>
                        <Link to="/productos/mineria" className="mira" >
                            <h5 className="links">Ver</h5>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex">
                <div className="card rounded-0 text-center mb-2">
                    <img src={imgHierro} alt="Materiales de hierro" srcset="" />
                    <div className="card-body">
                        <h4 className="card-title">Hierro</h4>
                        <p className="card-text text-secondary text-justify">El hierro ha sido el mejor amigo de la construcción en la era moderna, y por supuesto disponemos de gran variedad de suministros, las estructuras, vigas, perfiles, clavos, láminas y ángulos que requieres para avanzar con tus proyectos los encuentras aquí.</p>
                        <Link to="/productos/hierro" className="mira" >
                            <h5 className="links">Ver</h5>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex">
                <div className="card rounded-0 text-center mb-2">
                    <img src={imgPlastico} alt="Materiales de plástico" srcset="" />
                    <div className="card-body">
                        <h4 className="card-title">Plástico</h4>
                        <p className="card-text text-secondary text-justify">Desde reducciones y conexiones plásticas, hasta la solución perfecta para almacenamiento de agua y manejo de desechos, tenemos gran variedad de productos ferreteros elaborados en plástico.</p>
                        <Link to="/productos/plasticos" className="mira" >
                            <h5 className="links">Ver</h5>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-md-3 d-flex">
                <div className="card rounded-0 text-center mb-2">
                    <img src={imgTechos} alt="Techos" srcset="" />
                    <div className="card-body">
                        <h4 className="card-title">Techos</h4>
                        <p className="card-text text-secondary text-justify">Laminit, losacero, superlit, zinc, lo más buscado para el laminado de techos lo encuentras en nuestra ferretería, súmale calidad a tu construcción.</p>
                        <Link to="/productos/techos" className="mira" >
                            <h5 className="links">Ver</h5>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Highlights
