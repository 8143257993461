import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Plastico() {
    return (
        <div>
            <BreadcrumbProducts current="Plástico" />
            <div className="header-plastico">
                <h1 className="text-center pb-5 pt-5 category-title letra">Productos de Plástico</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Desde reducciones y conexiones plásticas, hasta la solución perfecta para almacenamiento de agua y manejo de desechos, tenemos gran variedad de productos ferreteros elaborados en plástico.</h4>
            </div>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tuberia/conexiones.jpg" alt="Conexiones de plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Conexiones y adaptaciones</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tuberia/mangueras.jpg" alt="Mangueras de Riego de Plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Mangueras de Riego</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tuberia/toma.jpg" alt="Tomás de plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tomas de plástico</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tuberia/tubos.jpg" alt="Tuberías de plástico" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tuberías</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Contenedores</h3>
            <div className="row">
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/desechos-120.jpg" alt="Contenedor de desechos sólidos de 120 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">120 Litros</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/desechos-1000.jpg" alt="Contenedor de desechos sólidos de 1000 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">1000 Litros</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Tanques de Agua</h3>
            <div className="row">
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/208.jpg" alt="Tanque de 208 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tanques de 208 litros</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/550-520.jpg" alt="Tanques de 520 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tanques de 520 y 550 litros</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/1000L.jpg" alt="Tanques de 1000 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tanques de 1000 litros</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/australiano.jpg" alt="Tanques Australiano de 7000 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tanques Australiano 7000 litros</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/plastico/tanques/cisterna.jpg" alt="Tanques Cisterna de 4500 litros" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cisterna de 4500 Litros</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plastico
