import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <Navbar color="light" light expand="md" className="mb-2">
                <NavbarBrand href="/"><img src="../img/logo.png" alt="logo Maxim Guayana" srcset="" class="logo" /></NavbarBrand>
                <Link to="/">
                    <div id="title">
                        <h4 style={{ lineHeight:"0px", marginTop:"20px" }}>Maxim Guayana</h4>
                        <small style={{ marginTop: "0px"}}>J-40236233-1</small>
                    </div>
                </Link>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <Link to="/productos/" className="nav-link" onClick={toggle}>
                                Productos
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/servicios/" className="nav-link" onClick={toggle}>
                                Servicios
                            </Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
}

export default Header;
