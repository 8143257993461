import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function SeguridadIndustrial() {
    return (
        <div>
            <BreadcrumbProducts current="Seguridad Industrial" />
            <div className="header-seguridad">
                <h1 className="text-center pb-5 pt-5 category-title letra">Seguridad Industrial</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">El primer objetivo a cumplir en toda obra es garantizar la seguridad al momento de ejecutarla, tenemos cascos, guantes, botas de seguridad y demás implementos para que dispongas del resguardo necesario en tus proyectos.</h4>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/botas/caterpillar.jpg" alt="Botas de seguridad perfil alto" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Botas de seguridad perfil alto</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/botas/negras.jpg" alt="Botas de seguridad perfil bajo" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Botas de seguridad perfil bajo</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/cascos/cascos.jpg" alt="Cascos de seguridad" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cascos de Seguridad</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/guantes/carnaza-cortos.jpg" alt="Guantes de Carnaza" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Guantes de Carnaza</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/guantes/puntos-2.jpg" alt="Guantes de puntos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Guantes de Puntos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/seguridad-industrial/lentes/lentes.jpg" alt="Lentes de seguridad" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Lentes de Seguridad</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeguridadIndustrial
