import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

function Lightbox() {
    // if toggler is updated when lightbox is closed it will open it
    // if toggler is updated when lightbox is opened it will close it
    const [toggler, setToggler] = useState(false);

    return (
        <>
            <button onClick={() => setToggler(!toggler)} className="ver-mas">
                Ver Más
            </button>
            <FsLightbox
                toggler={toggler}
                sources={[
                    '../img/productos/hierro/tuberia/circulares.jpg',
                    '../img/productos/hierro/tuberia/cuadrados.jpg',
                    '../img/productos/hierro/tuberia/rectangulares.jpg'
                ]}
            />
        </>
    );
}

export default Lightbox;