import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";

const ScrollToTopButton = ({ showBelow }) => {

    const [visible, setVisible] = useState(showBelow ? false : true);
    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!visible) setVisible(true)
        }else {
            if (visible) setVisible(false)
        }
        }
    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`,handleScroll)
        }
    })
    const handleClick = () =>{
        window[`scrollTo`]({top:0, behavior: `smooth`})
    }
    return (
        <div>
            {visible &&
            <FontAwesomeIcon icon={faChevronCircleUp} className="scroll-to-top" onClick={handleClick} />
            }
        </div>
    )
}

export default ScrollToTopButton
