import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Conexiones() {
    return (
        <div>
            <BreadcrumbProducts current="Conexions galvanizadas y hierro negro" />
            <div className="header-conexiones">
                <h1 className="text-center pb-5 pt-5 category-title letra">Conexiones Galvanizadas y Hierro Negro</h1>
            </div>
            <div className="description">
                <p className="text-justify pb-3 pt-2">El uso de acero galvanizado en las instalaciones hidráulicas es, fundamental. Esto es por la alta resistencia a los golpes, proporcionada por su propia estructura interna y por las gruesas paredes de los tubos y conexiones hechos con este material.</p>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/codo/codos.jpg" alt="Codo acero galvanizado" srcset="" />
                        <div className="card-body">
                            <h3 className="card-title">Codos</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/conexiones-chicago/conexion-chicago.jpg" alt="Conexiones chicago" />
                        <div className="card-body">
                            <h3 className="card-title">Conexiones Chicago</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/niple/niple.jpg" alt="Niples" />
                        <div className="card-body">
                            <h3 className="card-title">Niples</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/reducciones/reduccion.jpg" alt="reduccion.jpg" />
                        <div className="card-body">
                            <h3 className="card-title">Reducciones</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/tapones/tapones.jpg" alt="Tapones de hierro negro" />
                        <div className="card-body">
                            <h3 className="card-title">Tapones</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/union/union.jpg" alt="Uniones de tuberías" />
                        <div className="card-body">
                            <h3 className="card-title">Uniones</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/conexiones/valvula-check/valvula-check.jpg" alt="Válvulas check" />
                        <div className="card-body">
                            <h3 className="card-title">Válvulas Check</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Conexiones
