import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';
import PropTypes from 'prop-types';

const items = [
  {
    src: './img/slider_01.jpg',
    altText: 'equipos para minería oro coltan plata',
    caption: '',
    header: '',
    key: '1'
  },
  {
    src: './img/slider_02.jpg',
    altText: 'material de hierro vigas estructuras clavos',
    caption: '',
    header: '',
    key: '2'
  },
  {
    src: './img/slider_03.jpg',
    altText: 'material de plastico tanques de agua tuberias',
    caption: '',
    header: '',
    key: '3'
  },
  {
    src: './img/slider_04.jpg',
    altText: 'techos laminas acero zin laminit losacero',
    caption: '',
    header: '',
    key: '4'
  }
];

const Carousel = () => <UncontrolledCarousel items={items} indicators={false} />;

UncontrolledCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  indicators: PropTypes.bool, // default: true
  controls: PropTypes.bool, // default: true
  autoPlay: PropTypes.bool, // default: true
};

export default Carousel;