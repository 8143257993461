import React from 'react';
import { Link } from 'react-router-dom';

function Card({ title,imgSource, content,url }) {
    return (
        <div className="card rounded-0 text-center mb-2">
            <img src={imgSource} alt={title} srcset=""/>
            <div className="card-body">
                <h4 className="card-title">{title}</h4>
                <p className="card-text text-secondary text-justify">{content}</p>
                <Link to={url} className="mira" >
                    <h5 className="links">Ver</h5>
                </Link>
            </div>
        </div>
    )
}

export default Card
