import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';
import Lightbox from '../../components/Lightbox'

function Hierro() {
    return (
        <div>
            <BreadcrumbProducts current="Hierro" />
            <div className="header-hierro">
                <h1 className="text-center pb-5 pt-5 category-title letra">Hierro</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">El hierro ha sido el mejor amigo de la construcción en la era moderna, y por supuesto disponemos de gran variedad de suministros, las estructuras, vigas, perfiles, clavos, láminas y ángulos que requieres para avanzar con tus proyectos, los encuentras aquí.</h4>
            </div>
            <h3 className="text-center category-title" >Alambre</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/alambre/alambron.jpg" alt="Alambrón" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Alambrón</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/alambre/liso.jpg" alt="Alambre liso" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Liso</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/alambre/puas.jpg" alt="Alambre de púas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Púas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Estructuras</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/angulos/angulo.jpg" alt="Ángulo de hierro" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Ángulos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/barras/cuadrada.jpg" alt="Barra cuadrada de hierro" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Barras</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/cerchas/electrosoldada.jpg" alt="Cerchas electrosoldadas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Cerchas electrosoldadas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/pletinas/pletinas.jpg" alt="Pletinas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Pletinas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/vigas/viga-ipn.jpg" alt="Vigas IPN" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Vigas IPN</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/vigas/viga-upl.jpg" alt="Vigas UPL" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Vigas UPL</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Insumos</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/clavos/acero.jpg" alt="Clavos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Clavos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/clavos/zinc.jpg" alt="Clavos para techos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Clavos para techos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/grapas/grapas.jpg" alt="Grapas de hierro" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Grapas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Láminas</h3>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/laminas/galvanizada.jpg" alt="Láminas de hierro galvanizado" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Galvanizadas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/laminas/hierro-negro.jpg" alt="Láminas de hierro negro" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Hierro Negro</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/laminas/hierro-pulido.jpg" alt="Láminas de hierro pulido" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Hierro Pulido</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/laminas/lisa-estriadas.jpg" alt="Láminas lisas y estriadas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Lisas y estriadas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Perfiles</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/perfiles/omega.jpg" alt="Perfiles omega" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Perfiles Omega</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/perfiles/puerta.jpg" alt="Perfiles para puertas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Perfiles para puertas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/perfiles/ventana.jpg" alt="Perfiles para ventanas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Perfiles para ventanas</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Santa María</h3>
            <div className="row">
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/santa-maria/perfiles.jpg" alt="Perfiles para Santa María" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Perfiles para Santa María</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/santa-maria/resorte.jpg" alt="Resorte para Santa María" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Resorte para Santa María</h4>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className="text-center category-title" >Tuberías</h3>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/tuberia/estructural.jpg" alt="Tubería estructural" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tubería Estructural</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/tuberia/liviana-2.jpg" alt="Tubería Liviana" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tubería Liviana</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/hierro/tuberia/ventilacion.jpg" alt="Tubería de ventilación" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tubería de Ventilación</h4>
                        </div>
                    </div>
                </div>
            </div>
            <Lightbox />
        </div>
    )
}

export default Hierro
