import React from 'react';
import { Link } from 'react-router-dom';

function BreadcrumbProducts(props) {
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                    <Link to="/" className="breadcrumb-item">
                        Inicio
                    </Link>
                    <Link to="/productos" className="breadcrumb-item">
                        Productos
                    </Link>
                    <li className="breadcrumb-item active" aria-current="page">
                        {props.current}
                    </li>
                </ol>
            </nav>
        </div>
    )
}

export default BreadcrumbProducts
