import React, { useState,useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadSideMask } from "@fortawesome/free-solid-svg-icons"

const Coronavirus = (props) => {
    const [modal, setModal] = useState(true);
    const toggle = () => setModal(!modal);
    useEffect(() => {
        const timer = setTimeout(() => {
            setModal(false);
        }, 7500);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className="coronavirus modal-dialog-centered">
                <ModalHeader toggle={toggle} className="card-title text-center">La prevención es importante</ModalHeader>
                <FontAwesomeIcon icon={faHeadSideMask} className="iconos" style={{ fontSize: "6rem" }} />
                <ModalBody className="text-justify">
                    Recuerde respetar las normas de bioseguridad tanto al visitar nuestras instalaciones, como al momento de interacción social, cuidarnos es tarea de todos.
                </ModalBody>
            </Modal>
        </div>
    );
}

export default Coronavirus;
