import React, { Component } from 'react';
import Carousel from '../components/Carousel';
import Address from '../components/Address';
import Redes from '../components/Redes';
import Coronavirus from '../components/Coronavirus'
import Highlights from '../components/Highlights'

export class Home extends Component {
    render() {
        return (
            <div className="inside">
                <Coronavirus />
                <Carousel />
                <hr/>
                <h3 className="card-title text-center">Productos Destacados</h3>
                <hr />
                <Highlights />
                <Address />
                <Redes />
            </div>
        )
    }
}

export default Home
