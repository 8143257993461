import React from 'react'
import { Link } from 'react-router-dom';

function Services() {
    return (
        <div className="container justify-content-center align-items-center h-100">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mt-3">
                    <Link to="/" className="breadcrumb-item">
                        Home
                    </Link>
                    <li className="breadcrumb-item active" aria-current="page">
                        Servicios
                </li>
                </ol>
            </nav>
        <div class="row container">
            <div className="col-md-6 text-center mt-2 mb-2 d-flex">
                    <img src="../img/inside.jpg" alt="fachada de la ferreteria" class="product-img"srcset=""/>
            </div>
            <div className="col-md-6 text-justify servicios mt-2 mb-2">
                <p>Ferretería Maxim Guayana C.A. se debe sin lugar a dudas a nuestros clientes, es por eso que brindamos los mejores servicios para que ellos reciban lo que se merecen, más que clientes tratamos de ser sus aliados y amigos.</p>
                <p>Contamos con asesoramiento y ayuda en áreas como:</p>
                <ul>
                    <li>Ferretería</li>
                    <li>Pinturas</li>
                    <li>Herramientas eléctricas</li>
                    <li>Tanques de agua</li>
                </ul>
                <p>Nuestros Servicios son:</p>
                    <li>Empaque y despacho</li>
                    <li>Carga y descarga de mercadería</li>
                    <li>Envío de materiales</li>
                    <li>Ventas por mayoreo</li>
                <p>Contamos con una amplia gama de materiales de construcción, herramientas y más... Venga y visitenos para que se de cuenta de nuestro compromiso.</p>
            </div>
        </div>
        </div>
    )
}

export default Services
