import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Pinturas() {
    return (
        <div>
            <BreadcrumbProducts current="Pinturas" />
            <div className="header-pintura">
                <h1 className="text-center pb-5 pt-5 category-title letra">Pinturas</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Pinturas de caucho de la mejor calidad, para exteriores e interiores; contamos con variedad de colores, también tenemos disponibles esmaltes y fondos anticorrosivos.</h4>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/pinturas/caucho.jpg" alt="Pintura de caucho" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Caucho</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/pinturas/reinco-esmalte.jpg" alt="Pintura de esmalte" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Esmaltes Reinco</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/pinturas/reinco-fondo.jpg" alt="Pintura de fondo" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Fondo Reinco</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/pinturas/fondo-aya.jpg" alt="Pintura de fondo Aya" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Fondo Aya</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/pinturas/solimax.jpg" alt="Pintura Solimax" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Solimax</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pinturas
