import React from 'react';
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function Soldaduras() {
    return (
        <div>
            <BreadcrumbProducts current="Soldaduras" />
            <div className="header-soldadura">
                <h1 className="text-center pb-5 pt-5 category-title letra">Soldadura</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Tanto máquinas de soldar convencionales, como equipos de oxicorte, guantes, electrodos, máscaras y todo lo que necesites en el área de soldadura.</h4>
            </div>
            <div className="row">
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/soldadura/cascos.jpg" alt="Mascara para soldadura" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Máscaras para soldar</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/soldadura/electrodos.jpg" alt="Electrodos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Electrodos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/soldadura/maquina.jpg" alt="Maquina para soldar" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Máquina para soldar</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/soldadura/oxicorte-bombonas.jpg" alt="Equipos de oxicorte" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Equipos de oxicorte</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Soldaduras
