import React from 'react'
import BreadcrumbProducts from '../../components/BreadcrumbProducts';

function EquiposElectricos() {
    return (
        <div>
            <BreadcrumbProducts current="Equipos eléctricos" />
            <div className="header-equipos">
                <h1 className="text-center pb-5 pt-5 category-title letra">Equipos Eléctricos</h1>
            </div>
            <div className="description">
                <h4 className="text-justify pb-3 pt-2">Motores eléctricos, bombas de agua, esmeriles, trozadoras, polipastos, hidrojet, y muchos equipos más para que tengas las mejores herramientas y logres realizar cada proyecto ahorrando tiempo y con acabado profesional. Comuníquese con nosotros para recibir asesoría si lo desea, gustosamente le atenderemos.</h4>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/bombas-de-agua/truper.jpg" alt="Bombas de Agua" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Bombas de Agua</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/esmeril/dewalt.jpg" alt="Esmeriles" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Esmeriles</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/industriales/hidrolimpiadora.jpg" alt="Hidrojets" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Hidrojets</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/motores/varios.jpg" alt="Motores Eléctricos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Motores Eléctricos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/plantas/fermetal.jpg" alt="Plantas Eléctricas" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Plantas Eléctricas</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/industriales/polipasto.jpg" alt="Polipastos" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Polipastos</h4>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 d-flex">
                    <div className="card rounded-0 text-center mb-2">
                        <img className="product-img" src="/img/productos/equipos-electricos/tronzadora/dewalt.jpg" alt="Tronzadoras" srcset="" />
                        <div className="card-body">
                            <h4 className="card-title">Tronzadoras</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EquiposElectricos
