import React from 'react';
import Card from './Card';

import imgAcero from '../assets/img/productos/acero.jpg';
import imgConexiones from '../assets/img/productos/conexiones.jpg'
import imgElectricidad from '../assets/img/productos/electricidad.jpg'
import imgEquipos from '../assets/img/productos/equipos-electricos.jpg'
import imgFerreteria from '../assets/img/productos/ferreteria.jpg'
import imgHierro from '../assets/img/productos/hierro.jpg'
import imgMallas from '../assets/img/productos/mallas.jpg'
import imgMineria from '../assets/img/productos/mineria.jpg'
import imgPinturas from '../assets/img/productos/pinturas.jpg'
import imgPlastico from '../assets/img/productos/plastico.jpg'
import imgSeguridad from '../assets/img/productos/seguridad.jpg'
import imgSoldadura from '../assets/img/productos/soldadura.jpg'
import imgTechos from '../assets/img/productos/techos.jpg'

const cards = [
    {
        id: 1,
        title: "Acero Inoxidable",
        content: "Las propiedades como la resistencia a la corrosión y la capacidad de componer piezas higiénicas y estéticas hacen que el acero inoxidable sea un material muy atractivo para diversos fines. Mallas mesh para la construcción, tuberías y válvulas, Maxim Guayana dispone de la mejor calidad y variedad para apoyarte en tus proyectos.",
        img: imgAcero,
        url: "/productos/acero-inoxidable"
    },
    {
        id: 2,
        title: "Conexiones Galvanizadas y Hierro Negro",
        content: "Las conexiones de acero galvanizado y hierro negro son utilizadas para aplicaciones mecánicas y de presión, son aceptadas para usos en vapor, agua y líneas de aire. Diferentes diámetros y medidas, descubre la variedad de la que disponemos.",
        img: imgConexiones,
        url: "/productos/conexiones"
    },
    {
        id: 3,
        title: "Electricidad",
        content: "Desde bombillos, breakers, cables, interruptores, tableros y muchas mas herramientas y equipos para poder realizar el apartado eléctrico tanto de su hogar como de su empresa hasta la asesoría necesaria para obtener lo que se ajuste a sus necesidades, con la mejor calidad y precios.",
        img: imgElectricidad,
        url: "/productos/electricidad"
    },
    {
        id: 4,
        title: "Equipos Eléctricos",
        content: "Motores eléctricos, bombas de agua, esmeriles, trozadoras, polipastos, hidrojet, y muchos equipos más para que tengas las mejores herramientas y logres realizar cada proyecto ahorrando tiempo y con acabado profesional.",
        img: imgEquipos,
        url: "/productos/equipos-electricos"
    },
    {
        id: 5,
        title: "Ferretería",
        content: "Tenemos todo el equipo de ferretería que necesitas, desde implementos de albañilería, construcción, herramientas, cerraduras, sanitarios, mantos, cemento, pego, cal y más. No dejes de visitarnos y conocer todo lo que la Ferretería Maxim Guayana tiene para ti.",
        img: imgFerreteria,
        url: "/productos/ferreteria"
    },
    {
        id: 6,
        title: "Hierro",
        content: "El hierro ha sido el mejor amigo de la construcción en la era moderna, y por supuesto disponemos de gran variedad de suministros, las estructuras, vigas, perfiles, clavos, láminas y ángulos que requieres para avanzar con tus proyectos los encuentras aquí.",
        img: imgHierro,
        url: "/productos/hierro"
    },
    {
        id: 7,
        title: "Mallas",
        content: "En ciertos tipos de construcciones se requiere el uso de mallas específicas para brindar protección, resguardo y seguridad a ciertas áreas. Así como también, ayudar a otorgar solides a suelos, techos y paredes.",
        img: imgMallas,
        url: "/productos/mallas"
    },
    {
        id: 8,
        title: "Minería",
        content: "La extracción de minerales preciosos, como el oro y demás necesita del equipo adecuado para hacerlo de una manera óptima y obtener la mayor rentabilidad. La actividad minera tiene sus herramientas garantizadas en nuestra ferretería, con mangueras de poliuretano, generadores de vacío, válvulas y mucho más.",
        img: imgMineria,
        url: "/productos/mineria"
    },
    {
        id: 9,
        title: "Pinturas",
        content: "Pinturas de caucho de la mejor calidad, para exteriores e interiores; contamos con variedad de colores, también tenemos disponibles esmaltes y fondos anticorrosivos.",
        img: imgPinturas,
        url: "/productos/pinturas"
    },
    {
        id: 10,
        title: "Plásticos",
        content: "Desde reducciones y conexiones plásticas, hasta la solución perfecta para almacenamiento de agua y manejo de desechos, tenemos gran variedad de productos ferreteros elaborados en plástico.",
        img: imgPlastico,
        url: "/productos/plasticos"
    },
    {
        id: 11,
        title: "Seguridad Industrial",
        content: "El primer objetivo a cumplir en toda obra es garantizar la seguridad al momento de ejecutarla, tenemos cascos, guantes, botas de seguridad y demás implementos para que dispongas del resguardo necesario en tus proyectos.",
        img: imgSeguridad,
        url: "/productos/seguridad-industrial"
    },
    {
        id: 12,
        title: "Soldadura",
        content: "Tanto máquinas de soldar convencionales, como equipos de oxicorte, guantes, electrodos, máscaras y todo lo que necesites en el área de soldadura.",
        img: imgSoldadura,
        url: "/productos/soldaduras"
    },
    {
        id: 13,
        title: "Techos",
        content: "Lamilit, superlit, losacero, zinc, lo más buscado para el laminado de techos lo encuentras en nuestra ferretería, súmale calidad a tu construcción.",
        img: imgTechos,
        url: "/productos/techos"
    }
]
function Cards() {
    return (
        <div className="mt-2">
            <div className="row">
                {
                    cards.map(card => (
                        <div className="col-md-4 d-flex" key={card.id}>
                            <Card title={card.title} imgSource={card.img} content={card.content} url={card.url} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Cards
