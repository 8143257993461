import React from 'react'

function Address() {
    const address ="https://maps.app.goo.gl/crt1Kr5qQsDc9F5Y8"
    return (
        <div className="address category-title">
            <h3>Estamos cerca de ti</h3>
            <p>Encuéntranos en la Avenida Cisneros, al lado de la planta de la Coca Cola &copy;</p>
            <a href={address} target="_blank" rel="noreferrer"><h4 className="gps mb-2">Conoce cómo llegar</h4></a>
        </div>
    )
}

export default Address
