import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagramSquare, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons"

export default class Footer extends Component {
    render() {
        const date = new Date();
        return (
            <div id="footer">
                <footer className="text-center">
                    <div className="container">
                        <p style={{ marginBottom: "0px" }}><a href="https://maps.app.goo.gl/crt1Kr5qQsDc9F5Y8" target="_blank" rel="noreferrer"><strong>Dirección:</strong> Avenida Cisneros, próximo a la planta embotelladora Coca Cola FEMSA. San Félix, Estado Bolívar.</a> <a href="tel:+582867153509">0286-7153509</a> | <a href="tel:+584129490319">0412-9490319</a></p>
                    <div style={{marginBottom:"-20px"}}>
                        <a href="https://www.facebook.com/maximguayana" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare} style={{transform:"scale(2)", margin:"15px"}}/>
                        </a>
                        <a href="https://www.instagram.com/maximguayana" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagramSquare} style={{ transform: "scale(2)", margin: "15px" }}/>
                        </a>
                        <a href="https://wa.me/584129490319" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} style={{ transform: "scale(2)", margin: "15px" }}/>
                        </a>
                        <a href="mailto:maximguayana@gmail.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faAt} style={{ transform: "scale(2)", margin: "15px" }}/>
                        </a>
                    </div>
                    <br/>
                    <small style={{marginTop:"0px", lineHeight: "0px"}}>Maxim Guayana, C.A RIF J-40236233-1 &copy; {date.getFullYear()}</small>
                    </div>
                </footer>
            </div>
        );
    }
}