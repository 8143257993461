import React from 'react';
import {
    Card, CardTitle, CardDeck, CardBody, CardSubtitle
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faInstagramSquare, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Redes = (props) => {
    return (
        <div className="mb-2">
        <hr />
            <h3 className="card-title text-center">Síguenos en nuestras redes sociales</h3>
        <hr />
            <CardDeck className="mt-2">
                <Card>
                <a href="https://www.facebook.com/maximguayana" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookSquare} class="iconos mt-2" />
                <CardBody>
                    <CardTitle tag="h5" className="text-center mb-1">Facebook</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center">Maxim Guayana</CardSubtitle>
                </CardBody>
                </a>
            </Card>
                <Card>
                    <a href="https://www.instagram.com/maximguayana" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagramSquare} class="iconos mt-2" />
                <CardBody>
                    <CardTitle tag="h5" className="text-center mb-1">Instagram</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center">@maximguayana</CardSubtitle>
                </CardBody>
                </a>
            </Card>
            <Card>
                <a href="https://wa.me/584129490319" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} class="iconos mt-2" />
                <CardBody>
                    <CardTitle tag="h5" className="text-center mb-1">Whatsapp Business</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center">+584129490319</CardSubtitle>
                </CardBody>
                </a>
            </Card>
        </CardDeck>
        </div>
    );
};

export default Redes;